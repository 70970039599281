import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { confirmRegistration } from 'utils/api/auth';
import { LoadingOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

interface ConfirmRegistrationPageProps
  extends RouteComponentProps<{ token: string }> { }

export default function ConfirmRegistrationPage(
  props: ConfirmRegistrationPageProps
) {
  const { history, match } = props;
  useEffect(() => {
    confirm();
  }, []);

  const confirm = async () => {
    try {
      await confirmRegistration(match.params.token);
      history.push('/');
      Modal.info({
        title: 'Successfully verified',
        content: 'Your e-mail address was successfully verified',
        onOk: () => history.push('/'),
      });
    } catch (e) {
      history.push('/');
    }
  };

  return (
    <section>
      <div className="container">
        <LoadingOutlined spin />
      </div>
    </section>
  );
}
