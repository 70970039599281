export const validateEmail = (value?: string) => {
  if (!value) {
    return required('email')(value);
  } if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.trim())) {
    return `'${value.trim()}' is not a valid email address`;
  }
};

export const required = (name: string) => (value?: string) => {
  if (!value) {
    return `${name.toLowerCase()} field is required`;
  }
  return undefined;
};

export const maxValue = (max: number) => (value?: string) => {
  if (value && value.length > max) {
    return `max value ${max}`;
  }
};

export const validationConstructor = (...args: Function[]) => (
  value: string
) => {
  let error;

  args.find((validator) => {
    const validatorError = validator(value);

    if (validatorError) {
      error = validatorError;
      return true;
    }

    return false;
  });

  return error;
};
