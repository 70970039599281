import { PaginationItemsEnum } from "enums/pagination-items.enum";
import React from "react";
import Pagination from "../../../components/Pagination/Pagination";

export default function HowSection() {
  return (
    <section className="how-section">
      <Pagination activeItem={PaginationItemsEnum.HOW_SECTION} />
      <div className="container" id={PaginationItemsEnum.HOW_SECTION}>
        <div className="title-box">
          <h2 className="title">
            How OaaSiS works
            <span className="title-accent">How OaaSiS works</span>
          </h2>
        </div>
        <h3 className="heading">
          <span className="heading-accent">Make your Startup </span>
          Success sure via creating a Backbone of your organization!
        </h3>
        <div className="subtitle">
          Save Time&Efforts with OaaSiS support to Concentrate on your core
          Business Idea. <br />
          All your Start-up Business set up in one place:
        </div>
        <div className="how-card">
          <div className="how-card__circle">
            <i className="icon-settings" />
          </div>
          <div className="how-card__box">
            <div className="how-card__title">
              Software Development/ <br />
              App & Web
            </div>
            <div className="how-card__text">
              We leverage our expertise to provide you with full guidance for
              your software architecture and organizational setup.
            </div>
          </div>
        </div>
        <div className="how-card">
          <div className="how-card__circle">
            <i className="icon-employee" />
          </div>
          <div className="how-card__box">
            <div className="how-card__title">
              Organization Set Up <br />
              (HR& Structures, Processes)
            </div>
            <div className="how-card__text">
              Forget about figuring out how to avoid mistakes in staffing,
              scaling up your OPS workforces and creating workflows.
            </div>
          </div>
        </div>
        <div className="how-card">
          <div className="how-card__circle">
            <i className="icon-headphones" />
          </div>
          <div className="how-card__box">
            <div className="how-card__title">All around support</div>
            <div className="how-card__text">
              Avoid the risks of trial and error. We’ve been there already, and
              we know how to master this process and save you time.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
