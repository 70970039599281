import React from "react";
import { panelsData } from "./PrivacyPolicyContentData";

type PrivacyPolicyPageProps = {};

const PrivacyPolicyPage: React.FC<PrivacyPolicyPageProps> = () => (
  <section className="faq-page">
    <div className="container">
      <div className="title-box">
        <h2 className="title">
          OAASIS Privacy policy
          <span className="title-accent">OAASIS</span>
        </h2>
      </div>
      <h3 className="heading">Privacy policy</h3>
      {panelsData.map(({ title, content }) => (
        <div className="typography">
          <h3 className="typography-title">{title}</h3>
          <div className="typography-content">{content()}</div>
        </div>
      ))}
    </div>
  </section>
);

export default PrivacyPolicyPage;
