import { PricingSectionTypesEnum } from "enums/pricing-section-types.enum";

export type PricingTableItemDataType = {
  title: string;
  rates: {
    [key in PricingSectionTypesEnum]: boolean;
  };
};

export const tableData: PricingTableItemDataType[] = [
  {
    title: "IT project initiation",
    rates: {
      [PricingSectionTypesEnum.STARTER]: true,
      [PricingSectionTypesEnum.GROWER]: true,
      [PricingSectionTypesEnum.ADVANCED]: true,
    },
  },
  {
    title: "Technology stack",
    rates: {
      [PricingSectionTypesEnum.STARTER]: true,
      [PricingSectionTypesEnum.GROWER]: true,
      [PricingSectionTypesEnum.ADVANCED]: true,
    },
  },
  {
    title: "System Architecture",
    rates: {
      [PricingSectionTypesEnum.STARTER]: true,
      [PricingSectionTypesEnum.GROWER]: true,
      [PricingSectionTypesEnum.ADVANCED]: true,
    },
  },
  {
    title: "Organizations structure set up",
    rates: {
      [PricingSectionTypesEnum.STARTER]: true,
      [PricingSectionTypesEnum.GROWER]: true,
      [PricingSectionTypesEnum.ADVANCED]: true,
    },
  },
  {
    title: "HR Set-up",
    rates: {
      [PricingSectionTypesEnum.STARTER]: true,
      [PricingSectionTypesEnum.GROWER]: true,
      [PricingSectionTypesEnum.ADVANCED]: true,
    },
  },
  {
    title: "Governance / Management Structure",
    rates: {
      [PricingSectionTypesEnum.STARTER]: true,
      [PricingSectionTypesEnum.GROWER]: true,
      [PricingSectionTypesEnum.ADVANCED]: true,
    },
  },
  {
    title: "Project Scoping",
    rates: {
      [PricingSectionTypesEnum.STARTER]: false,
      [PricingSectionTypesEnum.GROWER]: true,
      [PricingSectionTypesEnum.ADVANCED]: true,
    },
  },
  {
    title: "Architectural blueprint",
    rates: {
      [PricingSectionTypesEnum.STARTER]: false,
      [PricingSectionTypesEnum.GROWER]: true,
      [PricingSectionTypesEnum.ADVANCED]: true,
    },
  },
  {
    title: "The prototype of the startup",
    rates: {
      [PricingSectionTypesEnum.STARTER]: false,
      [PricingSectionTypesEnum.GROWER]: true,
      [PricingSectionTypesEnum.ADVANCED]: true,
    },
  },
  {
    title: "Organizational Preparation for Go Live & Go Live support",
    rates: {
      [PricingSectionTypesEnum.STARTER]: false,
      [PricingSectionTypesEnum.GROWER]: true,
      [PricingSectionTypesEnum.ADVANCED]: true,
    },
  },
  {
    title: "Hyper Care support",
    rates: {
      [PricingSectionTypesEnum.STARTER]: false,
      [PricingSectionTypesEnum.GROWER]: true,
      [PricingSectionTypesEnum.ADVANCED]: true,
    },
  },
  {
    title: "Wiki for project",
    rates: {
      [PricingSectionTypesEnum.STARTER]: false,
      [PricingSectionTypesEnum.GROWER]: false,
      [PricingSectionTypesEnum.ADVANCED]: true,
    },
  },
  {
    title: "Source code",
    rates: {
      [PricingSectionTypesEnum.STARTER]: false,
      [PricingSectionTypesEnum.GROWER]: false,
      [PricingSectionTypesEnum.ADVANCED]: true,
    },
  },
  {
    title: "Project documentation",
    rates: {
      [PricingSectionTypesEnum.STARTER]: false,
      [PricingSectionTypesEnum.GROWER]: false,
      [PricingSectionTypesEnum.ADVANCED]: true,
    },
  },
  {
    title: "Live MVP",
    rates: {
      [PricingSectionTypesEnum.STARTER]: false,
      [PricingSectionTypesEnum.GROWER]: false,
      [PricingSectionTypesEnum.ADVANCED]: true,
    },
  },
  {
    title: "HR SOS Support",
    rates: {
      [PricingSectionTypesEnum.STARTER]: false,
      [PricingSectionTypesEnum.GROWER]: false,
      [PricingSectionTypesEnum.ADVANCED]: true,
    },
  },
  {
    title: "Operations management",
    rates: {
      [PricingSectionTypesEnum.STARTER]: false,
      [PricingSectionTypesEnum.GROWER]: false,
      [PricingSectionTypesEnum.ADVANCED]: true,
    },
  },
];
