import { ThunkDispatch } from '@reduxjs/toolkit';
import { LoginFormValue } from 'interfaces/login-form.interface';
import { Action, Dispatch } from 'redux';
import { signIn } from 'utils/api/auth';

const LOGIN = 'LOGIN';
const LOGOUT = 'LOGOUT';

export type ThunkAction<
  R, // Return type of the thunk function
  S, // state type used by getState
  E, // any "extra argument" injected into the thunk
  A extends Action // known types of actions that can be dispatched
> = (
  dispatch: ThunkDispatch<S, E, A>,
  getState: () => S,
  extraArgument: E
) => R;

const login = (values: LoginFormValue): any => async (dispatch: Dispatch) => {
  try {
    const { data } = await signIn(values);

    dispatch({
      type: LOGIN,
      payload: {
        userName: data.userName,
        accessToken: data.accessToken,
      },
    });

    return data;
  } catch (e) {
    console.log(e);
    return Promise.reject(e)
  }
};

const logout = () => ({
  type: LOGOUT,
  payload: {},
});

export const UserActionTypes = {
  LOGIN,
  LOGOUT,
};

export const UserActions = {
  login,
  logout,
};
