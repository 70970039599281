import React from 'react';
import { Link } from 'react-router-dom';

const AboutAsPage: React.FC = () => (
  <section className="about-section">
    <div className="container">
      <div className="title-box">
        <h2 className="title">
          About us
          <span className="title-accent">About us</span>
        </h2>
      </div>
      <h1 className="hero-title">
        OAASIS: Organization as a Service & Information System for&nbsp;
        <div className="hero-title--accent">
          Start-Up F
          <i className="icon-Group">
            <span className="path1" />
            <span className="path2" />
          </i>
          unders
        </div>
      </h1>
      <div className="hero-link-box">
        <Link to="/our_pricing" className="hero-link">
          <span>CHECK OUR SUBSCRIPTION PLANS</span>
          <i className="icon-link" />
        </Link>
      </div>

      <div className="about-grid">
        <div className="about-col">
          <div className="about-card">
            <i className="about-card__icon icon-flag" />
            <div className="about-card__text">
              Take your time for your core Ideas & Products.
            </div>
          </div>
        </div>
        <div className="about-col">
          <div className="about-card">
            <i className="about-card__icon icon-shuttle" />
            <div className="about-card__text">
              We make the launch of your start-up easy, safe and transparent.
            </div>
          </div>
        </div>
        <div className="about-col">
          <div className="about-card">
            <i className="about-card__icon icon-ftp" />
            <div className="about-card__text">
              With us you have a simple & independent way to turn your idea into
              functionable organization & IT system.
            </div>
          </div>
        </div>
        <div className="about-col">
          <div className="about-card">
            <i className="about-card__icon icon-web" />
            <div className="about-card__text">
              Develop your app/web, organizational and HR structure with us.
            </div>
          </div>
        </div>
        <div className="about-col">
          <div className="about-card">
            <i className="about-card__icon icon-hr" />
            <div className="about-card__text">
              Leverage our expertise in IT & HR to promote your own success.
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default AboutAsPage;
