import SubscriptionInput from "components/SubscriptionInput/SubscriptionInput";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo-light.svg";
import logoRun from "assets/images/running-logo-light.png";
import logoIt from "assets/images/it-craft-logo-light.svg";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <strong className="logo">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
        </strong>

        <div className="footer-row">
          <div className="footer-col">
            {/* <div className="footer-box">
              <div className="footer-title">
                <span>
                  About us
                </span>
              </div>
              <div className="footer-text">
                With a community of over 400 million users (and a majority age
                group being 18 to 29)
              </div>
            </div> */}

            <div className="footer-box">
              <div className="footer-title">
                <span>Contact Us </span>
              </div>
              <div className="footer-text">
                <address>
                  Kaiserswerther Str. 75 <br />
                  40476 Düsseldorf <br />
                  Germany
                </address>
                <ul>
                  <li>
                    <a href="mailto:welcome@oaasis.de">welcome@oaasis.de</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="hero-capture-box d-xs-none">
              <span>Powered By</span>
              <a href="https://runninghr.com/" target="_blank">
                <img width="68" src={logoRun} alt="Running HR" />
              </a>
              <div className="hero-line"></div>
              <a href="https://itechcraft.com/" target="_blank">
                <img width="70" src={logoIt} alt="IT Craft" />
              </a>
            </div>
          </div>

          <div className="footer-col fg">
            <div className="footer-subrow">
              <div className="footer-col">
                <div className="footer-box">
                  <div className="footer-title">
                    <span>Information </span>
                  </div>
                  <ul className="footer-list">
                    <li>
                      <Link
                        to="/about_as"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/privacy-policy"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        Privacy Policy{" "}
                      </Link>
                    </li>
                    <li>
                      <Link to="/imprint" onClick={() => window.scrollTo(0, 0)}>
                        Imprint
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/">More Search </Link>
                    </li> */}
                    {/* <li>
                      <Link to="/">Blog </Link>
                    </li> */}
                    {/* <li>
                      <Link to="/">Events </Link>
                    </li> */}
                  </ul>
                </div>
              </div>
              {/* <div className="footer-col">
                <div className="footer-box">
                  <div className="footer-title">
                    <span>Helpful Links </span>
                  </div>
                  <ul className="footer-list">
                    <li>
                      <Link to="/">Servicer </Link>
                    </li>
                    <li>
                      <Link to="/">Supports </Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">Privacy Policy </Link>
                    </li>
                    <li>
                      <Link to="/imprint">Imprint</Link>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>

          <div className="footer-col">
            <div className="footer-form">
              <div className="footer-title">More Info</div>
              <SubscriptionInput
                inputClassName="darken"
                inputPlaceholder="Enter Your Email"
                buttonClassName="btn-lg btn-primary"
              />
            </div>
          </div>
        </div>

        <div className="hero-capture-box d-sm-none">
          <span>Powered By</span>
          <a href="https://runninghr.com/" target="_blank">
            <img width="68" src={logoRun} alt="Running HR" />
          </a>
          <div className="hero-line"></div>
          <a href="https://itechcraft.com/" target="_blank">
            <img width="70" src={logoIt} alt="IT Craft" />
          </a>
        </div>

        <ul className="footer-socials">
          <li>
            <Link to="/">
              <i className="icon-twitter" />
            </Link>
          </li>
          <li>
            <Link to="/">
              <i className="icon-facebook" />
            </Link>
          </li>
          <li>
            <Link to="/">
              <i className="icon-linkedin" />
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  );
}
