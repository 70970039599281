import { applyMiddleware, createStore, Store } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { IAppStore, rootReducer } from './reducers';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const withReduxDevtools = (middlewares: any) =>
  composeWithDevTools(middlewares);

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

function configureStore(
  initialState: IAppStore = {} as IAppStore
): Store<IAppStore> {
  return createStore<any, any, any, any>(
    persistedReducer,
    initialState,
    withReduxDevtools(applyMiddleware(thunkMiddleware))
  );
}

export const store = configureStore();

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<IAppStore> = useSelector;
