import React from 'react';
import YouTube from 'react-youtube';

type PlayerProps = {
  videoId: string
}

const Player: React.FC<PlayerProps> = (props) => {
  const { videoId } = props;
  return (
    <YouTube
      videoId={videoId}
    />
  );
};

export default Player;
