import React from "react";
import { panelsData } from "./ImprintContentData";

type ImprintPageProps = {};

const ImprintPage: React.FC<ImprintPageProps> = () => (
  <section className="faq-page">
    <div className="container">
      <div className="title-box">
        <h2 className="title">
          OAASIS Imprint
          <span className="title-accent">OAASIS</span>
        </h2>
      </div>
      <h3 className="heading">Imprint</h3>
      {panelsData.map(({ title, content }) => (
        <div className="typography">
          {title && <h3 className="typography-title">{title}</h3>}
          <div className="typography-content">{content()}</div>
        </div>
      ))}
    </div>
  </section>
);

export default ImprintPage;
