import axios from 'axios';
import { LoginFormValue } from 'interfaces/login-form.interface';
import { RegistrationFormValue } from 'interfaces/registration-form.interface';

export function signUp(formValue: RegistrationFormValue): Promise<any> {
  return axios.post('/Auth/sign-up', {
    email: formValue.Email,
    password: formValue.Password,
    passwordConfirmation: formValue.SecondPassword,
    name: formValue.Name,
    phone: formValue.Phone,
    address: formValue.Address,
  });
}

export function confirmRegistration(token: string): Promise<any> {
  return axios.get(`/auth/confirm/${token}`);
}

export function signIn(values: LoginFormValue): Promise<any> {
  return axios.post(`/auth/sign-in`, values);
}

export function forgotPassword(email: string): Promise<any> {
  return axios.post(`/auth/forgot-password`, { email: email.trim() });
}

export function preRegistration(formValue: RegistrationFormValue): Promise<any> {
  return axios.post('/Auth/pre-registration', {
    email: formValue.Email.trim(),
    name: formValue.Name,
    phone: formValue.Phone,
    address: formValue.Address,
  });
}

