import React from 'react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import InputContainer from 'components/FieldContainers/InputContainer';
import { required, validateEmail, validationConstructor } from 'utils/validation/subscribe.validation';
import { Link, RouteComponentProps } from 'react-router-dom';
import { ForgotPasswordFormValue } from 'interfaces/forgot-password-form.interface';
import { forgotPassword } from 'utils/api/auth';
import { Modal } from 'antd';

interface ForgotPasswordPageProps extends RouteComponentProps<any> { }

export default function ForgotPasswordPagePage(props: ForgotPasswordPageProps) {
  const { history } = props;
  const [isLoading, setIsLoading] = React.useState(false);

  const onSubmitForm: any = async (
    values: ForgotPasswordFormValue,
    formikBag: FormikHelpers<ForgotPasswordFormValue>
  ) => {
    setIsLoading(true)
    try {
      await forgotPassword(values.Email);

      Modal.info({
        title: 'Forgot Password',
        content: 'Please check your Email address for a password',
        onOk: () => history.push('/login'),
      });
    } catch (e) {
      if (e?.response?.data?.message) {
        formikBag.setFieldTouched('Email');
        formikBag.setFieldError('Email', e?.response?.data?.message);
      }
    } finally {
      setIsLoading(false)
    }
  };

  return (
    <section className="auth-page forgot-page">
      <div className="container">
        <h3 className="heading">
          <span className="heading-accent">Forgot Password</span>
        </h3>
        <div className="subtitle">
          We just need your registered email address to send you a password
          reset message.
        </div>
        <Formik
          initialValues={{
            Email: '',
          }}
          onSubmit={onSubmitForm}
        >
          {(formikProps) => {
            return (
              <Form>
                <div className="form-wrapp">
                  <Field
                    required
                    name="Email"
                    component={InputContainer}
                    validate={validationConstructor(
                      required('Email'),
                      validateEmail
                    )}
                    size="large"
                  />
                  <div className="button-box">
                    <button disabled={isLoading} className="btn btn-lg btn-secondary">
                      Restore
                    </button>
                    <Link to="/login" className="form-link">
                      Log in
                    </Link>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </section>
  );
}
