import React from "react";
import { PaginationItemsEnum } from "enums/pagination-items.enum";
import { Link } from "react-router-dom";
import Pagination from "../../../components/Pagination/Pagination";

import Player from "components/Player/Player";

const MainSection: React.FC = () => {
  return (
    <section className="hero-section">
      <span className="line vertical main-line" />
      <span className="line horizontal line-1" />
      <span className="line vertical line-2" />
      <Pagination activeItem={PaginationItemsEnum.HERO_SECTION} />
      <div className="container" id={PaginationItemsEnum.HERO_SECTION}>
        <div className="title-box">
          <h2 className="title">
            About us
            <span className="title-accent">About us</span>
          </h2>
        </div>
        <h1 className="hero-title">
          OAASIS: Organization as a Service & Information System for{" "}
          <span className="hero-title--accent">
            Start-Up F
            <i className="icon-Group">
              <span className="path1" />
              <span className="path2" />
            </i>
            unders
          </span>
        </h1>
        <div className="hero-row">
          <div className="hero-col">
            <ul className="hero-list">
              <li>
                <span>Take your time for your core Ideas & Products.</span>
              </li>
              <li>
                <span>
                  We make the launch of your start-up easy, safe and
                  transparent.
                </span>
              </li>
              <li>
                <span>
                  With us you have a simple & independent way to turn your idea
                  into functionable organization & IT system.
                </span>
              </li>
              <li>
                <span>
                  Develop your app/web, organizational and HR structure with us.
                </span>
              </li>
              <li>
                <span>
                  Leverage our expertise in IT & HR to promote your own success.
                </span>
              </li>
            </ul>
            <div className="hero-link-box">
              <Link to="/our_pricing" className="hero-link">
                <span>CHECK OUR SUBSCRIPTION PLANS</span>
                <i className="icon-link" />
              </Link>
            </div>
          </div>
          <div className="hero-col hero-col-2">
            <div className="hero-video-box">
              <div className="hero-video">
                <Player videoId="a8Lr9BTzerY" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSection;
