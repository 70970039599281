import React from 'react';
import classNames from 'classnames';
import { Formik, Field, FormikHelpers } from 'formik';
// import { createSubscribe } from 'utils/api/subscribe';
import { validateEmail } from 'utils/validation/subscribe.validation';
import { SubscribeFormValue } from 'interfaces/subscribe-form.interface';
// import mixpanel from 'mixpanel-browser';
// import { MIXPANEL_EVENTS } from 'utils/constants';
import { useHistory } from 'react-router';

type SubscriptionInputProps = {
  inputPlaceholder?: string;
  inputWrapperClassName?: string;
  wrapperClassName?: string;
  inputClassName?: string;
  buttonClassName?: string;
  buttonText?: string;
};

export default function SubscriptionInput(props: SubscriptionInputProps) {
  const {
    inputWrapperClassName,
    wrapperClassName,
    inputClassName,
    buttonClassName,
    inputPlaceholder = 'Enter Your Email',
    buttonText = 'Subscribe',
  } = props;
  const history = useHistory();
  const [successText, setSuccessText] = React.useState('');
  const [timeout, setTimeoutState] = React.useState<NodeJS.Timeout>(setTimeout(() => null, 0));
  const [isLoading, setIsLoading] = React.useState(false);

  const onSubmitForm: any = async (
    values: SubscribeFormValue,
    formikBag: FormikHelpers<SubscribeFormValue>
  ) => {
    const { setFieldError } = formikBag;
    const error = validateEmail(values.email);

    if (error) {
      setFieldError('email', error);
      return;
    }

    setIsLoading(true)
    clearTimeout(timeout)

    try {
      // const data = await createSubscribe(values.email);
      setFieldError('email', undefined)
      // setSuccessText(data?.data?.message);

      setTimeoutState(setTimeout(() => {
        setSuccessText('');
      }, 5000));
      // mixpanel.track(MIXPANEL_EVENTS.SUBSCRIPTION, {
      //   email: values.email,
      // });
      history.push('/registration', {
        email: values.email
      })

    } catch (e) {
      setSuccessText('');
      setFieldError('email', e?.response?.data?.message);
      setTimeoutState(setTimeout(() => {
        setFieldError('email', undefined);
      }, 5000));
    } finally {
      setIsLoading(false)
    }
  };

  return (
    <Formik initialValues={{ email: '' }} onSubmit={onSubmitForm}>
      {(formikProps) => {
        const {
          handleSubmit,
          handleReset,
          errors,
          touched,
          values,
        } = formikProps;
        const displayEmailError = touched.email && errors.email;
        return (
          <form
            onSubmit={handleSubmit}
            onReset={handleReset}
            className={wrapperClassName}
          >
            <div className="form-input__box">
              <div
                className={classNames('input-box', inputWrapperClassName, {
                  error: displayEmailError,
                  'input-success': successText,
                })}
              >
                <Field
                  name="email"
                  className={classNames('form-control', inputClassName)}
                  placeholder={inputPlaceholder}
                />
                {displayEmailError && !successText && (
                  <span>{errors.email}</span>
                )}
                {successText && !errors.email && <span>{successText}</span>}
              </div>
            </div>
            <div className="btn-box">
              <button
                type="submit"
                disabled={isLoading || !values.email}
                className={classNames('btn', buttonClassName)}
              >
                {buttonText}
              </button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
}
