import React, { useState, useEffect } from "react";
import cx from "classnames";

import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import { IAppStore } from "store/reducers";
import { useAppDispatch } from "store";
import { UserActions } from "store/actions";
import logo from "../../assets/images/logo.svg";

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const user = useSelector((state: IAppStore) => state.user);
  const dispatch = useAppDispatch();
  const handlerLogOut = () => dispatch(UserActions.logout());
  const { pathname } = useLocation();

  useEffect(() => {
    setIsMenuOpen(false);
  }, [pathname]);

  return (
    <header className="header">
      <nav className="header-nav">
        <div className="header-logo">
          <strong className="logo">
            <Link to="/">
              <img src={logo} alt="logo" />
            </Link>
          </strong>
        </div>

        <ul className="header-menu-box">
          <li>
            <button
              type="button"
              className="header-menu-opener"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <i className="icon-burger" />
            </button>
            <ul className={cx("header-menu", { active: isMenuOpen })}>
              {/* <li>
                <Link to="/">
                  <span>What is OaaSiS</span>
                </Link>
              </li> */}
              <li>
                <Link to="/" onClick={() => setIsMenuOpen(false)}>
                  <span>Home</span>
                </Link>
              </li>
              <li>
                <Link to="/our_pricing" onClick={() => setIsMenuOpen(false)}>
                  <span>Pricing</span>
                </Link>
              </li>
              <li>
                <Link to="/about_as" onClick={() => setIsMenuOpen(false)}>
                  <span>About us</span>
                </Link>
              </li>
              <li>
                <Link to="/faq" onClick={() => setIsMenuOpen(false)}>
                  <span>FAQ</span>{" "}
                </Link>
              </li>
            </ul>
          </li>
        </ul>

        <ul className="header-auth">
          {!user.userName && (
            <>
              <li>
                <Link
                  to="/registration"
                  className="header-auth__btn header-auth__btn--register"
                  onClick={() => setIsMenuOpen(false)}
                >
                  <i className="icon-register" />
                  <span>Pre-Register</span>
                </Link>
              </li>
              {/* <li>
                <Link
                  to="/login"
                  className="header-auth__btn header-auth__btn--login"
                >
                  <i className="icon-login" />
                  <span>Log in</span>
                </Link>
              </li> */}
            </>
          )}
          {/* {user.userName && (
            <li>
              <button
                type="button"
                className="header-auth__btn header-auth__btn--login"
                onClick={handlerLogOut}
              >
                <i className="icon-login" />
                <span>Log out</span>
              </button>
            </li>
          )} */}
        </ul>
      </nav>
    </header>
  );
}
