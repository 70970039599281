import SubscriptionInput from 'components/SubscriptionInput/SubscriptionInput';
import React from 'react';

export default function FormSection() {
  return (
    <section className="form-section">
      <div className="wrapper">
        <div className="container">
          <div className="form-title">Join us</div>
          <div className="form-subtitle">and gain instant added value</div>
        </div>
      </div>
      <div className="container">
        <span className="line vertical line-3" />
        <div className="form-box">
          <div className="form-capture">
            Subscribe to our newsletter for the latest news, freebies, and
            exclusive content about OaaSiS.
          </div>
          <SubscriptionInput
            wrapperClassName="form-row"
            inputClassName="form-control-md"
            buttonClassName="btn-lg btn-secondary"
            inputPlaceholder="Enter Your Email"
          />
        </div>
      </div>
    </section>
  );
}
