import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import mixpanel from 'mixpanel-browser';

import Layout from 'components/Layout/Layout';
import Routes from 'Routes';
import { store } from 'store';
import { ID_DEVICE, MIXPANEL_EVENTS } from 'utils/constants';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { LoadingOutlined } from '@ant-design/icons';

import 'antd/dist/antd.css';
import './App.scss';
import ScrollToTop from 'components/ScrollToTop/ScrollToTop';

function App() {
  useEffect(() => {
    if (!localStorage.getItem(ID_DEVICE)) {
      localStorage.setItem(ID_DEVICE, uuidv4());
    }
    mixpanel.identify(localStorage.getItem(ID_DEVICE) as string);
    mixpanel.track(MIXPANEL_EVENTS.VISIT_AMOUNT, {
      device: localStorage.getItem(ID_DEVICE),
    });
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Provider store={store}>
        <PersistGate
          loading={<LoadingOutlined spin />}
          persistor={persistStore(store)}
        >
          <Layout>
            <Routes />
          </Layout>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
