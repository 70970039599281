import React from 'react';

import MainSection from 'pages/HomePage/HomePageSections/MainSection';
import HowSection from 'pages/HomePage/HomePageSections/HowSection';
import FormSection from 'pages/HomePage/HomePageSections/FormSection';
// import PriceSection from "components/PriceSection";
import OurSection from 'pages/HomePage/HomePageSections/OurSection';
import InfoSection from 'pages/HomePage/HomePageSections/InfoSection';

const HomePage = () => (
  <main>
    <MainSection />
    <HowSection />
    <FormSection />
    {/* <PriceSection /> */}
    <OurSection />
    <InfoSection />
  </main>
);

export default HomePage;
