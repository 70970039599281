import React from "react";
import { Link } from "react-router-dom";

export const panelsData = [
  {
    title: "",
    content: () => (
      <div>
        <p>This page contains information according to German § 5 TMG</p>

        <ul className="typography-list">
          <li>
            <i className="icon icon-user typography-list__icon"></i>
            <div className="typography-list__text">
              OaaSiS© <br />
              Dr. Ruslan Gurtoviy
            </div>
          </li>
          <li>
            <i className="icon icon-location-pin typography-list__icon"></i>
            <div className="typography-list__text">
              Kaiserswerther Str. 75 <br />
              40476 Düsseldorf. Germany
            </div>
          </li>
          <li>
            <i className="icon icon-location-arrow typography-list__icon"></i>
            <div className="typography-list__text">
              <a href="mailto:welcome@oaasis.de">welcome@oaasis.de</a> <br />
              <span>Website: <a href="https://oaasis.de/">www.oaasis.de</a></span>
            </div>
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: "Disclaimer. Liability for contents",
    content: () => (
      <div>
        The insights and contents of Oaasis pages were created with state of the
        art care. However, we cannot assume any guarantee for the correctness
        and completeness of the contents provided by 3rd party vendors if any.
        Along to § 7 Abs. 1 TMG as a service provider we are responsible for own
        contents on this page in accordance with the general laws. According to
        §§ 8 to 10 TMG, as a service provider we are not forced to monitor
        transmitted or stored third-party information or to verify circumstances
        that indicate illegal activity. Obligations to remove or block the use
        of information in accordance with general laws remain same. However,
        liability in this respect is only possible from the point when knowledge
        appeared of a concrete legal violation. As soon as we become informed of
        such situation, we will remove these contents as soon as possible.
      </div>
    ),
  },
  {
    title: "Liability for links",
    content: () => (
      <div>
        Our services may contain links to external websites of third parties, on
        whose contents we have no impact on. Therefore, we cannot assure any
        liability for these external contents. The respective provider or
        operator of the websites is always responsible for the contents of the
        linked websites. The linked websites and domains were verified for
        possible legal issues at the time of connecting. However, a permanent
        content-related control of the linked websites does not make sense
        without concrete evidence of a legal issue or violation. We will remove
        such links immediately after becoming aware of any legal issues.
      </div>
    ),
  },
  {
    title: "Copyright",
    content: () => (
      <div>
        The contents of this website are subject to copyright, unless otherwise
        indicated, and may not be redistributed, modified or copied as a whole
        or in parts without the prior written acceptance of Oaasis. The images
        and videos integrated into this website may not be used without the
        prior written acceptance of Oaasis. Graphics contained on the websites
        are partially subject to copyright of third parties. Insofar as the
        content on this website was not created by the operator, the copyrights
        of third parties should and will be respected. In particular, any
        contents of third parties are noticed and defined as such. In case you
        become aware of a copyright violation, we kindly ask you for appropriate
        reference. Immediately as we become aware of any legal based violations,
        we will remove affected content or graphics immediately.
      </div>
    ),
  },
];
