import React from 'react';
import classNames from "classnames";
import { get } from "lodash";
import { PricingTableItemDataType } from "./pricingPageData";
import { PricingSectionTypesEnum } from 'enums/pricing-section-types.enum';

export const DesktopTableItem: React.FC<{ service: PricingTableItemDataType, linkType: PricingSectionTypesEnum }> = (props) => {
  const { service, linkType } = props;
  const { title, rates } = service;

  return (
    <div className="table-row">
      <div className="table-cell">{title}</div>
      <div className="table-subrow">
        {Object.keys(rates).map((key: string) => (
          <div className={
            classNames("table-subcell", {
              active: linkType === key,
            })
          }>
            {get(rates, key) && <div><i className="icon-check"></i></div>}
          </div>
        ))}
      </div>
    </div>
  )
}