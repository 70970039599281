import React from "react";
import { Link } from "react-router-dom";

export const panelsData = [
  {
    title: "General information",
    content: () => (
      <div>
        <p>
          The protection of personal data during your visit of our homepage is
          very important to us. Below you will find information about which
          personal data we process, for what purpose, on what basis and for how
          long. We provide you with this data privacy policy to inform you of
          how we handle your personal data collected on this website.
        </p>
        <p>Data protection responsible:</p>
        <ul className="typography-list">
          <li>
            <i className="icon icon-user typography-list__icon"></i>
            <div className="typography-list__text">Dr. Ruslan Gurtoviy</div>
          </li>
          <li>
            <i className="icon icon-location-pin typography-list__icon"></i>
            <div className="typography-list__text">
              Kaiserswerther Str. 75 <br />
              40476 Düsseldorf. Germany
            </div>
          </li>
          <li>
            <i className="icon icon-location-arrow typography-list__icon"></i>
            <div className="typography-list__text">
              <a href="mailto:welcome@oaasis.de">welcome@oaasis.de</a>
            </div>
          </li>
        </ul>
      </div>
    ),
  },
  {
    title: "Access and activity logs (“server logs”)",
    content: () => (
      <div>
        Each entry to this website automatically causes general protocol data,
        so-called server logs, to be collected. As a rule, this information is a
        pseudonym and thus does not allow for inferences about the identity of
        an individual. Without this data, it would, in some cases, be
        technically impossible to deliver or display the contents of the
        software. In addition, processing this data is absolutely necessary
        under security aspects, in particular for access, input, transfer, and
        storage control. Furthermore, this anonymous information can be used for
        statistical reasons and for optimizing services and technology. In
        addition, the log files can be checked and analyzed retrospectively when
        unlawful use of the software is suspected. The legal basis for this is
        section 15 subsection 1 of the German Telemedia Act (TMG), as well as
        article 6 (1) f of the General Data Protection Regulation. Generally,
        data such as the domain name of the website, the web browser and
        web-browser version, the operating system, the IP address, as well as
        the timestamp of the access to the software is collected. The scope of
        this log process does not go above the common log scope of any other
        site on the web. These access logs are stored for a period of up to 7
        days. There is no right to object to this.
      </div>
    ),
  },
  {
    title: "Error logs",
    content: () => (
      <div>
        So-called error logs are generated for the purpose of identifying and
        fixing bugs. This is absolutely necessary to ensure Oaasis can react
        promptly to possible problems with displaying and implementing content
        (legitimate interest). As a rule, this data is a pseudonym and thus does
        not give possibility for inferences about the identity of an individual.
        The legal basis for this is section 15 subsection 1 of the German
        Telemedia Act (TMG), as well as article 6 (1) f) of the GDPR. When an
        error message appears, general data such as the domain name of the
        website, the web browser and web-browser version, the operating system,
        the IP address, as well as the time stamp upon occurrence of the
        respective error information and/or specification is collected. These
        error logs are stored up to 7 days. There is no eligibility to object to
        this.
      </div>
    ),
  },
  {
    title: "Use of cookies",
    content: () => (
      <div>
        <p>
          So-called cookies are used on parts of this website. They are small
          text files which are stored on the device with which you access this
          website. Different categories of cookies are used:
        </p>
        <ul className="typography-mark">
          <li>
            Core cookies are necessary for ensuring the core functionality of
            our website
          </li>
          <li>
            Function cookies are cookies that are used for tracking user
            behavior on our website to improve the functionality of our website
          </li>
          <li>
            Marketing related cookies are those ones we are using for serving
            interest-based ads
          </li>
          <li>
            External media cookies: These cookies serve the purpose of
            displaying external content (such as videos or maps)
          </li>
        </ul>
        <p>
          The legal basis for the use of essential cookies is Art. 6 (1) (f)
          GDPR -a legitimate interest. Our legitimate interest for the usage of
          these cookies is providing a functioning website. The legal basis for
          the usage of the other cookies is Art. 6 (1) (a) GDPR — your consent.
          Without your consent no non-essential cookies will be set. You can
          withdraw your consent anytime with effect for the future by sending
          message to welcome@oaasis.de.
        </p>
      </div>
    ),
  },
  {
    title: "Use of third-party provider tools",
    content: () => (
      <div>
        <p>
          In order to provide and continuously improve our services, we are
          using the services of the following third-party providers which may
          also process personal data. These third-party providers have been
          selected diligently and in line with the requirements of the GDPR.
        </p>
        <b>a. Google</b>
        <p>
          Unless otherwise stated in the data privacy policy, the operator of
          all Google services mentioned here is Google Ireland Limited,Google
          Building Gordon House, 4 Barrow St, Dublin, D04 E5W5, Ireland.
        </p>
        <b>i. Google Analytics</b>
        <p>
          This website uses the service “Google Analytics”. The operator of this
          service is the company Google Ireland Limited, Google Building Gordon
          House, 4 Barrow St, Dublin, D04 E5W5, Ireland. Google Analytics is a
          web analysis service, and, by placement of cookies and the information
          acquired by this, it enables us to make inferences about user behavior
          on our website. The information generated by the cookies is sent to a
          Google server in the USA and stored there. Our website uses the
          service Google Analytics on an exclusively pseudonymous basis. Your IP
          address is only recorded in abbreviated form and is hence anonymized.
        </p>
        <p>The following data is collected and processed:</p>
        <ul className="typography-mark">
          <li>IP-Addresses (anonymized)</li>
          <li>Usage data</li>
          <li>Click path</li>
          <li>App updates</li>
          <li>Browser information</li>
          <li>Device information</li>
          <li>JavaScript support</li>
          <li>Pages visited</li>
          <li>Referrer URL</li>
          <li>Downloads</li>
          <li>Flash-version</li>
          <li>Location information</li>
          <li>Widget interactions</li>
          <li>Date and time of visit</li>
        </ul>
        <p>
          The legal basis of the processing is your consent according to Art. 6
          (1)(a) GDPR.. If you do not want Google Analytics to collect and
          process the aforementioned data, you can refuse your consent or
          withdraw it at any time with effect for the future. The data will be
          stored for as long as it is necessary for the purpose of the
          procession. The data will be deleted as soon as it is no longer needed
          for the processing purposes.
        </p>
        <p>
          As part of the processing, the data may be transferred to the
          following recipients besides Google Ireland Limited:
        </p>
        <ul className="typography-mark">
          <li>Google LLC.</li>
          <li>Alphabet Inc.</li>
        </ul>
        <p>
          Data may be transferred to the USA as part of processing by Google
          Analytics. The security of the transmission is ensured by so-called
          standard contractual clauses, which guarantee that the processing of
          personal data is subject to a security level that corresponds to that
          of the GDPR. If the standard contractual clauses are not sufficient to
          ensure an adequate level of security, we will obtain your consent in
          accordance with Art. 49 (1)(a) GDPR prior to the data processing.
        </p>
        <b>ii. YouTube</b>
        <p>
          This website uses the service “YouTube” to insert videos into the
          page. The operator of the software necessary for this purpose is the
          company Google Ireland Limited Google Building Gordon House, 4 Barrow
          St, Dublin, D04 E5W5, Ireland. The integration of YouTube content is
          carried out in “extended privacy mode”. This ensures that YouTube does
          not initially store cookies on your device. As a result, YouTube no
          longer stores any information about visitors until you watch the
          video. When you click on the video, your IP address is sent to
          YouTube, which tells YouTube that you have watched the video. If you
          are logged in to YouTube, this information is also associated with
          your account. This can be prevented by logging out of YouTube before
          viewing the video.
        </p>
        <p>Accordingly, the following data can be collected and processed:</p>

        <ul className="typography-mark">
          <li>IP Addresses</li>
          <li>Referrer URL</li>
          <li>Device Information</li>
          <li>Watched videos</li>
        </ul>

        <p>
          The legal basis of the processing is your consent according to Art. 6
          (1)(a) GDPR. If you do not want YouTube to collect and process the
          aforementioned data, you can refuse your consent or withdraw it at any
          time with effect for the future. The data will be stored for as long
          as it is necessary for the purpose of the procession. The data will be
          deleted as soon as it is no longer needed for the processing purposes.
        </p>

        <p>
          As part of the processing, the data may be transferred to the
          following recipients besides Google Ireland Limited:
        </p>

        <ul className="typography-mark">
          <li>Google LLC.</li>
          <li>Alphabet Inc.</li>
        </ul>

        <p>
          Data may be transferred to the USA as part of processing by YouTube.
          The security of the transmission is ensured by so-called standard
          contractual clauses, which guarantee that the processing of personal
          data is subject to a security level that corresponds to that of the
          GDPR. If the standard contractual clauses are not sufficient to ensure
          an adequate level of security, we will obtain your consent in
          accordance with Art. 49 (1)(a) GDPR prior to the data processing.
        </p>

        <b>iii. Google Web Fonts</b>
        <p>
          On this website the service Google Web Fonts is used. The service is
          provided by Google Ireland Limited Google Building Gordon House, 4
          Barrow St, Dublin, D04 E5W5, Ireland. Google Web Fonts enables us to
          load and display external fonts, so-called Google Fonts, on our
          website. Google Web Fonts is locally integrated on our website. This
          means that the fonts are not loaded from Google servers.
        </p>

        <p>
          In the context of processing via Google Web Fonts, the following
          personal data is collected and processed:
        </p>

        <ul className="typography-mark">
          <li>IP-Addresses (anonymized)</li>
        </ul>

        <p>
          The legal basis for this processing is Art. 6 (1)(f) GDPR — a
          legitimate interest. Our legitimate interest in the processing is to
          present the website in an attractive and user-friendly manner. Local
          hosting ensures that no data is transferred to Google, and no data
          transfer takes place. Personal data is stored for as long as it is
          necessary to fulfill the purpose of processing. The data is deleted as
          soon as it is no longer required for the purpose.
        </p>

        <b>b. LinkedIn</b>

        <p>
          We use the retargeting tool and the conversion tracking of LinkedIn
          Ireland, Wilton Plaza, Wilton Place, Dublin 2, Ireland (“LinkedIn”).
          For this purpose the LinkedIn Insight Tag is incorporated into our
          webpage. LinkedIn uses it to collect statistical, pseudonymized data
          from your visit and use of our website and to provide us with the
          corresponding aggregated statistics based on these. In addition, this
          information serves to be able to show you relevant offers and
          recommendations specific to your interests, after you have inquired on
          the website about certain services, information and offers. The
          information in this regard is stored in a cookie. More information
          about Data Privacy of LinkedIn can be found <a href="https://www.linkedin.com/legal/privacy-policy">here</a>.
        </p>
        <p>In this process this data will be collected and processed:</p>
        <ul>
          <li>IP Address</li>
          <li>Device information</li>
          <li>Browser information</li>
          <li>Referrer URL</li>
          <li>Timestamp</li>
        </ul>
        <p>
          The legal basis of the processing is your consent according to Art. 6
          (1)(a) GDPR. If you do not want LinkedIn to collect and process the
          aforementioned data, you can refuse your consent or withdraw it at any
          time with effect for the future. The data will be stored for as long
          as it is necessary for the purpose of the procession. The data will be
          deleted as soon as it is no longer needed for the processing purposes.
          Data may be transferred to the USA as part of processing by LinkedIn.
          The security of the transmission is ensured by so-called standard
          contractual clauses, which guarantee that the processing of personal
          data is subject to a security level that corresponds to that of the
          GDPR. If the standard contractual clauses are not sufficient to ensure
          an adequate level of security, we will obtain your consent in
          accordance with Art. 49 (1)(a) GDPR prior to the data processing.
        </p>
      </div>
    ),
  },
  {
    title: "Request by e-mail, on-line Form",
    content: () => (
      <div>
        In case of preregistration or e-mail contact Provided personal data
        (e-mail, name) will be stored and processed by us for the purpose of
        handling your request. We do not pass these data or share it with third
        parties. <br />
        The processing of these data is based on Art. 6 para. 1 lit. b GDPR, if
        your request is related to the execution of a contract or if it is
        necessary to carry out pre-contractual measures. In all other cases, the
        processing is based on your consent (Article 6 (1) a GDPR) and/or on our
        legitimate interests (Article 6 (1) (f) GDPR), since we have a
        legitimate interest in the effective processing of requests addressed to
        us. <br />
        The data sent by you to us via contact requests remain with us until you
        request us to delete, revoke your consent to the storage or the purpose
        for the data storage lapses (e.g. after completion of your request).
        Mandatory statutory provisions – in particular statutory retention
        periods – remain unaffected.
      </div>
    ),
  },
  {
    title: "Rights of data subjects",
    content: () => (
      <div>
        IIf the company Oaasis processes personal data as data controller, then
        you as the data subject have certain rights derived from Chapter III
        GDPR, which depend on the legal basis and purpose of the processing.
        These rights include when relevant especially the right to information
        (Art. 15 GDPR), the right to rectification (Art. 16 GDPR), the right to
        cancellation (Art. 17 GDPR), the right to restriction of processing
        (Art. 18 GDPR), the right to data portability (Art. 20 GDPR), and the
        right to objection (Art. 21 GDPR). If the processing of personal data is
        based on your consent, then you have the right pursuant to Art. 7 III
        GDPR to revoke this agreement granted under data protection rules.{" "}
        <br />
        Please contact welcome@oaasis.de in order to assert you rights as data
        subject regarding the data processed for the purposes of using this
        website. Please be aware that you must contact the data controller
        directly to assert your rights as data subject derived from the
        processing by us, as data processor of our customers. We reserve the
        right not to respond to corresponding queries or to redirect them to the
        corresponding companies. <br />
        If Oaasis as the controller processes your personal data, you as the
        data subject have rights under Chapter III of the EU General Data
        Protection Regulation (GDPR), depending on the legal basis and the
        purpose of processing, in particular the right of access (article 15 of
        the GDPR) and the rights to rectification (article 16 of the GDPR),
        erasure (article 17 of the GDPR), restriction of processing (article 18
        of the GDPR), and data portability (article 20 of the GDPR), as well as
        the right to object (article 21 of the GDPR). If the personal data is
        processed with your acceptance, you have the right to withdraw this
        acceptance under article 7 III of the GDPR. <br />
        Please contact the data protection officer of Oaasis to assert your
        rights with regards to the data processed for the operation of this
        software (see section 2). Please note that you must reach out personally
        to the dp controller in order to assert your rights as a data subject
        from the processing of personal data by Oaasis as subprocessor on behalf
        of our customers. We reserve the right not to answer such questions
        directly or to pass them on to the controller of this data processing.
      </div>
    ),
  },
  {
    title: "Right to lodge a complaint",
    content: () => (
      <div>
        We would hereby like to inform you that pursuant to article 77 GDPR you
        have the right to lodge a complaint with the supervisory authority in
        case you believe that your personal data have been processed not
        correctly by Oaasis.
      </div>
    ),
  },
  {
    title: "Right to object",
    content: () => (
      <div>
        You can object to the use of your data by using the appropriate opt-out
        or by making the adjustments in the User centrics management system.
      </div>
    ),
  },
  {
    title: "Final clauses",
    content: () => (
      <div>
        Oaasis reserves the right to change this data privacy policy at any
        point in time to assure that it is in line with the current legal
        requirements at all times, or in order to acknowledge and reflect
        changes in the services offered, for example when new services created
        or changes are made on the website. In this case, the new data privacy
        statement applies to any later visit of this software.
      </div>
    ),
  },
];
