import React from 'react';
import { Link } from 'react-router-dom';

export const panelsData = [
  {
    title: 'What is OaaSiS about?',
    content: () => (
      <div>
        OaaSiS is a product for start-up founders.This is the simplest &
        independent way to turn your idea into a functionable organization & IT
        system.
      </div>
    ),
  },
  {
    title: 'How can OaaSiS support my business growth? ',
    content: () => (
      <div>
        <p>
          OaaSiS products are defined by your subscription plan, based on your
          needs as a starter, grower or advanced enterprise. We also provide an
          IT platform, which supports start-up founders and owners by:
        </p>
        <ol>
          <li>
            consulting on the execution of establishing your organizational
            structure and providing process optimization strategies.
          </li>
          <li>
            creating an IT architecture and building a marketable mobile app.
          </li>
          <li>
            providing SOS support when unexpected business challenges arise,
            that question business continuity.
          </li>
          <li>
            delivering sustainable operations for transactional topics defined
            with the founder.
          </li>
        </ol>
      </div>
    ),
  },
  {
    title: 'Will OaaSiS back me up with some documents I can use in practice? ',
    content: () => (
      <div>
        Yes. All of our programs give you access to a database of modern
        documentations, that will help you in orchestrating your business
        successfully. Grower and Advanced programs give you access to solutions,
        that we will tailor specifically for your business complexity, current
        situation, and current development plan.
      </div>
    ),
  },
  {
    title: 'Why I might need OaaSiS?',
    content: () => (
      <div>
        Starting a new business is always a fantastic moment. That idea grows,
        grows and grows in your head. Then it reality hits you: the
        organizational burden, issues with creating a perfect IT landscape, the
        employee turnover - all become overwhelming. Unfortunately, even a great
        idea can drown in bureaucratic noise. We’ve been there already, and we
        want to share our experiences with you. We know how to leverage
        early-stage problems and prepare your company for sustainable success.
      </div>
    ),
  },
  {
    title: 'How can I pay for services at OaaSiS? ',
    content: () => (
      <div>
        Payments can be made using PayPal, Apple Pay or direct bank transfer.
      </div>
    ),
  },
  {
    title: 'Why do I need a subscription? ',
    content: () => (
      <div>
        Whenever you feel lost, we’ll be there to back you up. A subscription
        gives you access to direct consultancy, which is limited in the number
        of hourly sessions per month. You also get a huge discount for upgrading
        to our more advanced plans.
      </div>
    ),
  },
  {
    title: 'How you will work with me? ',
    content: () => (
      <div>
        It depends on you. We provide you with the documents, templates, and
        prototypes to promote your success. We also conduct consultancy calls
        and even direct workshops with you and your team.
      </div>
    ),
  },
  {
    title: 'How much does it cost? ',
    content: () => (
      <div>
        Our subscription plans vary in pricing, depending on how much time and
        the level of support you need. More Information on these plans is
        available <Link to="/our_pricing">here</Link>.
      </div>
    ),
  },
  {
    title: 'What time zone are you operating in? ',
    content: () => (
      <div>
        Our support is provided within regular central European working hours
        (09:00 – 17:00 CET). However, we are flexible and happy to adjust to
        your needs.
      </div>
    ),
  },
  {
    title: 'What if I use OaaSiS, and then want to go my own way? ',
    content: () => (
      <div>
        Your contract with us flexible to your business interests. Whenever you
        decide to go your own way, you can cancel your service without any
        additional costs.
      </div>
    ),
  },
];
