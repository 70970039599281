import React from 'react';
import { get } from "lodash";
import { PricingTableItemDataType } from "./pricingPageData";

export const MobileTableItem: React.FC<{ service: PricingTableItemDataType }> = (props) => {
  const { service } = props;
  const { title, rates } = service;

  return (
    <div className="table-mobile">
      <div className="table-mobile__head">{title}</div>
      {Object.keys(rates).map((key: string) => (
        <div className="table-mobile__row">
          <span>{key}</span>
          {get(rates, key)
            ? <i className="icon-check"></i>
            : <i className="icon-minus"></i>}
        </div>
      ))}
    </div>
  )
}