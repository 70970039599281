import React from "react";
import { PricingSectionTypesEnum } from "enums/pricing-section-types.enum";
import { Link } from "react-router-dom";
import classNames from "classnames";
import * as H from "history";
import { ILocationState } from "interfaces/location-state.interface";
import { tableData } from "./pricingPageData";
import MediaQuery from "react-responsive";
import { DesktopTableItem } from "./DesktopTableItem";
import { MobileTableItem } from "./MobileTableItem";

type PricingPageProps = {
  location: H.Location<ILocationState>;
};

const PricingPage: React.FC<PricingPageProps> = (props) => {
  const {
    location: { state },
  } = props;
  const linkType = state?.pricingSectionType as PricingSectionTypesEnum;

  return (
    <section className="price-page">
      <div className="container">
        <h3 className="heading">
          Choose one of the programs and let your Idea flourish
        </h3>
        <div className="table-box">
          <div className="table">
            <div className="table-top">
              <div className="table-title">
                <h2 className="title">
                  our pricing
                  <span className="title-accent">our pricing</span>
                </h2>
              </div>
              <div>
                <div className="table-head">
                  <div className="table-head__cell">
                    <div className="table-head__desc">
                      <strong>+</strong> <span>Launch Membership</span>
                    </div>
                    <div className="table-head__price">
                      € 99 <span>Month</span>
                    </div>
                  </div>
                  <div className="table-head__cell table-head__caption">
                    Membership! Get instant help and discuss your challenges
                    with OaaSiS HR and IT experts (2 hours monthly), and receive
                    up to 15% discount on our products
                  </div>
                  <div className="table-head__cell">
                    <Link
                      to="/registration"
                      className="btn btn-white table-btn btn-outline"
                    >
                      book your first free consulting
                    </Link>
                  </div>
                </div>
                <div className="table-price">
                  <div className="table-price__cell">
                    <div className="table-price__title">
                      Starter / Consulting
                    </div>
                    <div className="table-price__value">
                      <strong>€ 1,800</strong>
                    </div>
                    <div className="table-price__caption">
                      <div className="table-price__subtitle">
                        You have your basic IT & Org Setup
                      </div>
                      <Link
                        to="/registration"
                        className="btn btn-primary table-btn"
                      >
                        book your first free consulting
                      </Link>
                    </div>
                  </div>
                  <div className="table-price__cell">
                    <div className="table-price__title">Grower / Prototype</div>
                    <div className="table-price__value">
                      <strong>€3,500</strong>
                    </div>
                    <div className="table-price__caption">
                      <div className="table-price__subtitle">
                        You have your IT & Org Prototype
                      </div>
                      <Link
                        to="/registration"
                        className="btn btn-primary table-btn"
                      >
                        book your first free consulting
                      </Link>
                    </div>
                  </div>
                  <div className="table-price__cell">
                    <div className="table-price__title">
                      Advanced / Take-off
                    </div>
                    <div className="table-price__value">
                      <strong>€ 6,000 </strong>
                    </div>
                    <div className="table-price__caption">
                      <div className="table-price__subtitle">
                        You have your IT & Org Take-Off
                      </div>
                      <Link
                        to="/registration"
                        className="btn btn-primary table-btn"
                      >
                        book your first free consulting
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <MediaQuery query="(min-width: 1024px)">
                {(matches) =>
                  tableData.map((service) =>
                    matches ? (
                      <DesktopTableItem service={service} linkType={linkType} />
                    ) : (
                      <MobileTableItem service={service} />
                    )
                  )
                }
              </MediaQuery>
            </div>
            <div className="table-footer-row">
              <div></div>
              <div className="table-footer-subrow">
                <div
                  className={classNames("table-footer-subcell", {
                    active: linkType === PricingSectionTypesEnum.STARTER,
                  })}
                >
                  <div className="table-footer__title">
                    You have your basic IT & Org Setup
                  </div>
                  <Link
                    to="/registration"
                    className={classNames("btn btn-secondary table-btn ", {
                      "btn-outline":
                        linkType !== PricingSectionTypesEnum.STARTER,
                    })}
                  >
                    book your first free consulting
                  </Link>
                </div>
                <div
                  className={classNames("table-footer-subcell", {
                    active: linkType === PricingSectionTypesEnum.GROWER,
                  })}
                >
                  <div className="table-footer__title">
                    You have your IT & Org Prototype
                  </div>
                  <Link
                    to="/registration"
                    className={classNames("btn btn-secondary table-btn ", {
                      "btn-outline":
                        linkType !== PricingSectionTypesEnum.GROWER,
                    })}
                  >
                    book your first free consulting
                  </Link>
                </div>
                <div
                  className={classNames("table-footer-subcell", {
                    active: linkType === PricingSectionTypesEnum.ADVANCED,
                  })}
                >
                  <div className="table-footer__title">
                    You have your advanced IT & Org
                  </div>
                  <Link
                    to="/registration"
                    className={classNames("btn btn-secondary table-btn ", {
                      "btn-outline":
                        linkType !== PricingSectionTypesEnum.ADVANCED,
                    })}
                  >
                    book your first free consulting
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingPage;
