import React, { ReactChildren, ReactChild } from 'react';

import Footer from 'components/Layout/Footer';
import Header from './Header';

type LayoutProps = {
  children: ReactChild[] | ReactChild | ReactChildren;
};

const Layout: React.FC<LayoutProps> = ({ children }) => (
  <>
    <Header />
    {children}
    <Footer />
  </>
);

export default Layout;
