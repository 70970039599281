import { combineReducers, Reducer } from '@reduxjs/toolkit';
import user, { IUserState } from './user.reducer';

export interface IAppStore {
  user: IUserState;
}

export const rootReducer: Reducer<IAppStore> = combineReducers({
  user,
});
