import classNames from 'classnames';
import { FieldInputProps, FormikProps } from 'formik';
import React from 'react';
import { get } from 'lodash';
import { Input, InputProps } from 'antd';

type FooterProps = {
  field: FieldInputProps<string>;
  form: FormikProps<any>;
  wrapperClassName?: string;
  className?: string;
  placeholder?: string;
  required?: boolean;
  type: InputProps['type'];
};

export default function InputContainer(props: FooterProps) {
  const {
    form,
    type,
    field,
    required,
    className,
    placeholder,
    wrapperClassName,
    ...others
  } = props;
  const error = get(form.errors, field.name);
  const isTouched = get(form.touched, field.name);
  const Component = type === 'password' ? Input.Password : Input;

  return (
    <div
      className={classNames('input-box', wrapperClassName, {
        error: isTouched && error,
        required,
      })}
    >
      <Component
        {...field}
        {...others}
        type={type}
        placeholder={placeholder || `Enter ${field.name}`}
        className={classNames('input', className)}
      />
      {isTouched && error && <span>{error}</span>}
    </div>
  );
}
