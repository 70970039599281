import { AnyAction, Reducer } from 'redux';
import { UserActionTypes } from 'store/actions';

export interface IUserState {
  userName?: string;
  accessToken?: string;
}

const initialState: IUserState = {};

const reducer: Reducer<IUserState> = (
  state: IUserState = initialState,
  action: AnyAction
) => {
  switch (action.type) {
    case UserActionTypes.LOGIN: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case UserActionTypes.LOGOUT: {
      return {};
    }

    default: {
      return state;
    }
  }
};

export default reducer;
