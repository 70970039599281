import React from 'react';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import InputContainer from 'components/FieldContainers/InputContainer';
import { required, validateEmail, validationConstructor } from 'utils/validation/subscribe.validation';
import { Link, RouteComponentProps } from 'react-router-dom';
import { LoginFormValue } from 'interfaces/login-form.interface';
import { useAppDispatch } from 'store';
import { UserActions } from 'store/actions';

interface LoginPageProps extends RouteComponentProps<any> { }

export default function LoginPage(props: LoginPageProps) {
  const { history } = props;
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(false);

  const onSubmitForm: any = async (
    values: LoginFormValue,
    formikBag: FormikHelpers<LoginFormValue>
  ) => {
    setIsLoading(true)
    try {
      await dispatch(UserActions.login(values));

      history.push('/');
    } catch (e) {
      if (e?.response?.data?.errorMessage) {
        formikBag.setFieldTouched('Email');
        formikBag.setFieldError('Email', e?.response?.data?.errorMessage);
      }
    } finally {
      setIsLoading(false)
    }
  };

  return (
    <section className="auth-page login-page">
      <div className="container">
        <div className="title-box">
          <h2 className="title">
            Log in
            <span className="title-accent">Log in</span>
          </h2>
        </div>
        <h3 className="heading">
          <span className="heading-accent">Hello, Welcome back</span>
        </h3>
        <div className="subtitle">
          If you would to log in to our service, please complete the required
          fields.
        </div>
        <Formik
          initialValues={{
            Name: '',
            Address: '',
            Phone: '',
            Email: '',
            Password: '',
            SecondPassword: '',
          }}
          onSubmit={onSubmitForm}
        >
          {(formikProps) => {
            return (
              <Form>
                <div className="form-wrapp">
                  <Field
                    required
                    name="Email"
                    component={InputContainer}
                    validate={validationConstructor(
                      required('Email'),
                      validateEmail
                    )}
                    size="large"
                  />
                  <Field
                    required
                    type="password"
                    name="Password"
                    component={InputContainer}
                    validate={required('Password')}
                    size="large"
                  />
                  <div className="button-box">
                    <button disabled={isLoading} className="btn btn-lg btn-secondary">Log In</button>
                    <Link to="/forgot-password" className="form-link">
                      Forgot Password
                    </Link>
                  </div>
                </div>
                <div className="form-text">
                  <div>
                    If you are not yet subscribed, please{' '}
                    <Link to="/registration">Create an account</Link> or contact{' '}
                    <a href="mailto:no-welcome@oaasis.de">Customer Support</a>.
                  </div>
                  <div>
                    If you are experiencing issues with logging into your
                    account, please contact{' '}
                    <a href="mailto:no-welcome@oaasis.de">Customer Support</a>.
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </section>
  );
}
