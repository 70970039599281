import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import classnames from 'classnames';
import { PaginationItemsEnum } from 'enums/pagination-items.enum';
import { formattedNumber } from 'utils/general';

type PaginationPropsType = {
  activeItem: PaginationItemsEnum;
};

const Pagination: React.FC<PaginationPropsType> = (props) => {
  const { activeItem } = props;
  const renderItems = Object.values(PaginationItemsEnum);

  return (
    <ul className="pagination">
      {renderItems.map((key, index) => (
        <li key={key}>
          <AnchorLink
            href={`#${key}`}
            className={classnames({
              active: key === activeItem,
            })}
          >
            {formattedNumber(index + 1)}
          </AnchorLink>
        </li>
      ))}
    </ul>
  );
};

export default Pagination;
