import React from 'react';
import { Collapse } from 'antd';
import { panelsData } from './FAQContentData';

const { Panel } = Collapse;

type FAQPageProps = {};

const FAQPage: React.FC<FAQPageProps> = () => (
  <section className="faq-page">
    <div className="container">
      <div className="title-box">
        <h2 className="title">
          OAASIS FAQs
          <span className="title-accent">OAASIS FAQs</span>
        </h2>
      </div>
      <h3 className="heading">Hello, how can we help?</h3>
      <Collapse expandIconPosition="right" ghost defaultActiveKey={[]}>
        {panelsData.map(({ title, content }, index) => (
          <Panel header={title} key={index.toString()}>
            {content()}
          </Panel>
        ))}
      </Collapse>
    </div>
  </section>
);

export default FAQPage;
