import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HomePage from 'pages/HomePage/HomePage';
import AboutAsPage from 'pages/AboutAsPage/AboutAsPage';
import PricingPage from 'pages/PricingPage/PricingPage';
import FAQPage from 'pages/FAQPage/FAQPage';
import RegistrationPage from 'pages/RegistrationPage/RegistrationPage';
import ConfirmRegistrationPage from 'pages/RegistrationPage/ConfirmRegistrationPage';
import LoginPage from 'pages/LoginPage/LoginPage';
import ForgotPasswordPagePage from 'pages/ForgotPasswordPage/ForgotPasswordPage';
import PrivatePolicyPage from 'pages/PrivacyPolicyPage/PrivacyPolicyPage';
import ImprintPage from 'pages/ImprintPage';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/about_as" component={AboutAsPage} exact />
    <Route path="/our_pricing" component={PricingPage} exact />
    <Route path="/faq" component={FAQPage} exact />
    <Route path="/registration" component={RegistrationPage} exact />
    <Route
      path="/confirm-registration/:token"
      component={ConfirmRegistrationPage}
    />
    <Route path="/login" component={LoginPage} />
    <Route path="/forgot-password" component={ForgotPasswordPagePage} />
    <Route path="/privacy-policy" component={PrivatePolicyPage} />
    <Route path="/imprint" component={ImprintPage} />
    <Route path="/" component={HomePage} exact />
  </Switch>
);

export default Routes;
