import { PaginationItemsEnum } from "enums/pagination-items.enum";
import React from "react";
import Pagination from "components/Pagination/Pagination";
import { Link } from "react-router-dom";
import { PricingSectionTypesEnum } from "enums/pricing-section-types.enum";

export default function OurSection() {
  return (
    <section className="our-section">
      <Pagination activeItem={PaginationItemsEnum.OUR_SECTION} />
      <div className="container" id={PaginationItemsEnum.OUR_SECTION}>
        <span className="line vertical line-1" />
        <div className="title-box">
          <h2 className="title">
            our pricing
            <span className="title-accent">our pricing</span>
          </h2>
        </div>
        <h3 className="heading">
          <div className="heading-accent">Choose one of the programs </div>
          and let your Idea flourish
        </h3>
        <div className="our-row">
          <div className="our-col">
            <div className="our-card">
              <div className="our-card__text">
                <div className="our-card__title">Starter / Consulting</div>
                <div className="our-card__tag">
                  <strong>€ 1,800</strong>
                </div>
                <ul className="our-card__list">
                  <li>
                    <i className="icon-check-outline" />
                    <span>IT project initiation</span>
                  </li>
                  <li>
                    <i className="icon-check-outline" />
                    <span>Technology stack</span>
                  </li>
                  <li>
                    <i className="icon-check-outline" />
                    <span>System Architecture</span>
                  </li>
                  <li>
                    <i className="icon-check-outline" />
                    <span>Organizations structure set up</span>
                  </li>
                  <li>
                    <i className="icon-check-outline" />
                    <span>HR Set-up</span>
                  </li>
                  <li>
                    <i className="icon-check-outline" />
                    <span>Governance / Management Structure</span>
                  </li>
                </ul>
              </div>
              <div>
                <div className="our-card__capture">
                  You have your basic IT & Org Setup
                </div>
                <div className="our-card__btn">
                  <Link
                    className="btn btn-lg btn-primary"
                    to={{
                      pathname: "/our_pricing",
                      state: {
                        pricingSectionType: PricingSectionTypesEnum.STARTER,
                      },
                    }}
                  >
                    Book your first free consulting
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="our-col our-col--main">
            <div className="our-card">
              <div className="our-card__text">
                <div className="our-card__title">Grower / Prototype</div>
                <div className="our-card__tag">
                  <strong>€3,500</strong>
                </div>
                <ul className="our-card__list">
                  <li>
                    <i className="icon-check-outline" />
                    <span>Project Scoping</span>
                  </li>
                  <li>
                    <i className="icon-check-outline" />
                    <span>Architectural blueprint</span>
                  </li>
                  <li>
                    <i className="icon-check-outline" />
                    <span>The prototype of the startup</span>
                  </li>
                  <li>
                    <i className="icon-check-outline" />
                    <span>
                      Organizational Preparation for Go Live & Go Live support
                    </span>
                  </li>
                  <li>
                    <i className="icon-check-outline" />
                    <span>Hyper Care support</span>
                  </li>
                  <li className="our-card__link">
                    <Link to="/">
                      <i className="icon-plus" />
                      <span>All Starter features</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <div className="our-card__capture">
                  You have your IT & Org Prototype
                </div>
                <div className="our-card__btn">
                  <Link
                    className="btn btn-lg btn-primary"
                    to={{
                      pathname: "/our_pricing",
                      state: {
                        pricingSectionType: PricingSectionTypesEnum.GROWER,
                      },
                    }}
                  >
                    Book your first free consulting
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="our-col">
            <div className="our-card">
              <div className="our-card__text">
                <div className="our-card__title">Advanced / Take-off</div>
                <div className="our-card__tag">
                  <strong>€ 6,000 </strong>
                </div>
                <ul className="our-card__list">
                  <li>
                    <i className="icon-check-outline" />
                    <span>Wiki for project</span>
                  </li>
                  <li>
                    <i className="icon-check-outline" />
                    <span>Source code</span>
                  </li>
                  <li>
                    <i className="icon-check-outline" />
                    <span>Project documentation</span>
                  </li>
                  <li>
                    <i className="icon-check-outline" />
                    <span>Live MVP</span>
                  </li>
                  <li>
                    <i className="icon-check-outline" />
                    <span>HR SOS Support</span>
                  </li>
                  <li>
                    <i className="icon-check-outline" />
                    <span>Operations management</span>
                  </li>
                  <li className="our-card__link">
                    <Link to="/">
                      <i className="icon-plus" />
                      <span>All Starter & Grower features</span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <div className="our-card__capture">
                  You have your advanced IT & Org
                </div>
                <div className="our-card__btn">
                  <Link
                    className="btn btn-lg btn-primary"
                    to={{
                      pathname: "/our_pricing",
                      state: {
                        pricingSectionType: PricingSectionTypesEnum.ADVANCED,
                      },
                    }}
                  >
                    Book your first free consulting
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
