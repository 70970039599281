import React from "react";
import { Modal } from "antd";
import { Field, Form, Formik, FormikHelpers } from "formik";
import InputContainer from "components/FieldContainers/InputContainer";
import {
  maxValue,
  required,
  validateEmail,
  validationConstructor,
} from "utils/validation/subscribe.validation";
import { Link, RouteComponentProps, useLocation } from "react-router-dom";
import { RegistrationFormValue } from "interfaces/registration-form.interface";
import { preRegistration, signUp } from "utils/api/auth";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_EVENTS } from "utils/constants";

interface RegistrationPageProps extends RouteComponentProps<any> {}

export default function RegistrationPage(props: RegistrationPageProps) {
  const { history } = props;
  const [isLoading, setIsLoading] = React.useState(false);
  const location = useLocation<{ email: string }>();

  const onSubmitForm: any = async (
    values: RegistrationFormValue,
    formikBag: FormikHelpers<RegistrationFormValue>
  ) => {
    if (values.Password !== values.SecondPassword) {
      formikBag.setFieldTouched("SecondPassword");
      formikBag.setFieldError(
        "SecondPassword",
        '"Confirm Password" should be equal to the "Password" value.'
      );
      return;
    }
    setIsLoading(true);

    try {
      await preRegistration(values);

      mixpanel.track(MIXPANEL_EVENTS.REGISTRATION, {
        email: values.Email,
      });

      Modal.info({
        title: "Mail Sent",
        content: "Please check your e-mail to verify your e-mail address",
        onOk: () => history.push("/"),
      });
    } catch (e) {
      await formikBag.setFieldTouched("Email");
      await formikBag.setFieldError("Email", e?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <section className="auth-page register-page">
      <div className="container">
        <div className="title-box">
          <h2 className="title">
            Registration
            <span className="title-accent">Registration</span>
          </h2>
        </div>
        <h3 className="heading">
          <span className="heading-accent">Welcome to oaasis</span>
        </h3>
        <div className="subtitle">
          If you would to subscribe to our service, please complete the required
          fields.
        </div>
        <Formik
          initialValues={{
            Name: "",
            Address: "",
            Phone: "",
            Email: location?.state?.email as string,
            // Password: '',
            // SecondPassword: '',
          }}
          onSubmit={onSubmitForm}
        >
          {(formikProps) => {
            return (
              <Form>
                <div className="form-grid">
                  <Field
                    required
                    name="Name"
                    maxLength={100}
                    component={InputContainer}
                    validate={validationConstructor(
                      required("Name"),
                      maxValue(100)
                    )}
                    size="large"
                  />
                  <Field
                    name="Address"
                    maxLength={250}
                    component={InputContainer}
                    validate={maxValue(250)}
                    size="large"
                  />
                  <Field
                    name="Phone"
                    maxLength={50}
                    component={InputContainer}
                    validate={maxValue(50)}
                    size="large"
                  />
                  <Field
                    required
                    name="Email"
                    component={InputContainer}
                    validate={validationConstructor(
                      required("Email"),
                      validateEmail
                    )}
                    size="large"
                  />
                  {/* <Field
                    required
                    type="password"
                    name="Password"
                    component={InputContainer}
                    validate={required('Password')}
                    size="large"
                  />
                  <Field
                    required
                    type="password"
                    name="SecondPassword"
                    component={InputContainer}
                    placeholder="Сonfirm Your Password"
                    validate={required('Сonfirm Password')}
                    size="large"
                  /> */}
                </div>
                <div className="button-box">
                  <button
                    disabled={isLoading}
                    className="btn btn-lg btn-secondary"
                  >
                    book your first free consulting
                  </button>
                  <span className="auth-text">
                    <span className="form-link-capture">
                      Get benefits of early birds registration
                    </span>{" "}
                    {/* <Link to="/login" className="form-link">
                      Log In
                    </Link>{' '} */}
                  </span>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </section>
  );
}
